// top page js

topFunction();

function topFunction(){
  if (!document.body.classList.contains('home')) return;
  kvAnimation();
}

function kvAnimation(){
  const video = document.getElementById('introMov');
  const skipBtn = $('.c-skip');
  // ロード
  video.addEventListener('canplay', () => {
    $('.c-loading').fadeOut(400);
    _resizeMov();
    video.play();
    _resizeKv();
    skip();
  }, false);

  // 動画終了時
  video.addEventListener('ended', () => {
    setTimeout(function(){
      $('.o-load-intro').fadeOut(1200);
      $('.o-keyvisual').addClass('-loaded');
      skipBtn.fadeOut(1200);
    },600);
  });

  function _resizeMov() {
    const cover_w = 1920;
    const cover_h = 1080;
    const cover_ratio = cover_w / cover_h;
    _cover();
    $(window).on('resize',_cover);
    function _cover(){
      let style = {};
      // ウィンドウサイズを取得
      const win_w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const win_h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  
      // ウィンドウの縦の比率を求める
      const win_ratio = win_w / win_h;
  
      if(cover_ratio > win_ratio){
        // 画像の比率がウィンドウより大きければ、画像の高さを100%にする
        style = {
          'width': 'auto',
          'height': '100vh',
        };
  
      }else{
        // そうじゃない場合は、画像の幅を100%にする
        style = {
          'width': '100vw',
          'height': 'auto',
        };
  
      }
      $('.o-load-intro video').css(style);
    }
  }


  function _resizeKv() {
    let element = new Image() ;
    element.src = $('.o-keyvisual__bg img').attr('src');
    element.onload = () => {
      const cover_w = element.naturalWidth;
      const cover_h = element.naturalHeight;
      const cover_ratio = cover_w / cover_h;
      _cover();
      $(window).on('resize',_cover);
      function _cover(){
        let style = {};
        // ウィンドウサイズを取得
        const win_w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const win_h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    
        // ウィンドウの縦の比率を求める
        const win_ratio = win_w / win_h;
    
        if(cover_ratio > win_ratio){
          // 画像の比率がウィンドウより大きければ、画像の高さを100%にする
          style = {
            'width': 'auto',
            'height': '100vh',
          };
    
        }else{
          // そうじゃない場合は、画像の幅を100%にする
          style = {
            'width': '100vw',
            'height': 'auto',
          };
    
        }
        $('.o-keyvisual__bg img').css(style);
      }
    }
  }

  function skip() {
    video.addEventListener('timeupdate', function() {
      if(video.currentTime >= 11.5) {
        skipBtn.fadeOut(1200);
      }
    });
    skipBtn.on('click', function () {
      $('.o-load-intro').fadeOut(1200);
      $('.o-keyvisual').addClass('-loaded');
      skipBtn.fadeOut(1200);
    });
  }
}