iconSvg();

function iconSvg(){

  const items = document.getElementsByClassName('iconSvg');
  const iconArray = {
    'home': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.196 486.196"><path d="M481.708 220.456l-228.8-204.6c-.4-.4-.8-.7-1.3-1-5-4.8-13-5-18.3-.3l-228.8 204.6c-5.6 5-6 13.5-1.1 19.1 2.7 3 6.4 4.5 10.1 4.5 3.2 0 6.4-1.1 9-3.4l41.2-36.9v238.6c0 18.7 15.2 34 34 34H169.908c17.6 0 31.9-14.3 31.9-31.9v-121.3c0-2.7 2.2-4.9 4.9-4.9h72.9c2.7 0 4.9 2.2 4.9 4.9v121.3c0 17.6 14.3 31.9 31.9 31.9h72.2c19 0 34-18.7 34-42.6v-228.7l41.2 36.9c2.6 2.3 5.8 3.4 9 3.4 3.7 0 7.4-1.5 10.1-4.5 4.8-5.6 4.3-14.1-1.2-19.1zm-86.2 66.7v145.1c0 9.7-4.8 15.6-7 15.6h-72.2c-2.7 0-4.9-2.2-4.9-4.9v-121.1c0-17.6-14.3-31.9-31.9-31.9h-72.9c-17.6 0-31.9 14.3-31.9 31.9v121.3c0 2.7-2.2 4.9-4.9 4.9H97.608c-3.8 0-7-3.1-7-7v-262.8l151.8-135.6 153.1 136.9v107.6z"/></svg>',
    'arrow': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" overflow="visible"><path fill="none" d="M0 0h24v24H0z"/><path d="M17 12c0-.2 0-.3-.1-.4 0-.1-.1-.1-.1-.2l-.1-.1-2.9-3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l1.2 1.3H8c-.6 0-1 .4-1 1s.4 1 1 1h5.6l-1.3 1.3c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0l3-3c.1-.1.2-.2.2-.3.1-.1.1-.3.1-.4z"/><path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"/></svg>',
    'triangle': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8"><path fill-rule="evenodd" d="M4.644 8L0 0h9.288L4.644 8z"/></svg>',
  };

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const name = item.getAttribute('data-icon');
    if (name) {
      const icon = iconArray[name];
      item.innerHTML = icon;
    }
  }
}
